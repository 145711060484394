import React from "react";

export default function BobPolarizedPhoton({
  bobBasis,
  bobPhoton,
  secondaryBasis,
  withEve,
}) {
  return (
    <div
      className={`${
        withEve ? "animate-bob-down-slide-w-eve" : "animate-bob-down-slide"
      } `}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 489.925 489.925"
        space="preserve"
        className="h-16"
      >
        <g>
          <g>
            <circle
              className="bg-blue-300 fill-current text-blue-700 animate-pulse"
              cx="244.963"
              cy="244.925"
              r="50.8"
            />
            {((parseInt(bobBasis) === 0 &&
              parseInt(secondaryBasis) === 0 &&
              parseInt(bobPhoton) === 1) ||
              (parseInt(bobBasis) === 0 &&
                parseInt(secondaryBasis) === 1 &&
                parseInt(bobPhoton) === 1)) && (
              <>
                {/* Top */}

                <path
                  d="M244.963,137.325c5,0,9.1-4.1,9.1-9.1v-97.3l20.2,20.2c1.8,1.8,4.1,2.7,6.4,2.7s4.6-0.9,6.4-2.7c3.5-3.5,3.5-9.3,0-12.8
			l-35.7-35.7c-3.5-3.5-9.3-3.5-12.8,0l-35.7,35.7c-3.5,3.5-3.5,9.3,0,12.8s9.3,3.5,12.8,0l20.2-20.2v97.3
			C235.863,133.325,239.963,137.325,244.963,137.325z"
                />
                {/* Bottom */}
                <path
                  d="M202.863,451.525l35.7,35.7c1.8,1.8,4.1,2.7,6.4,2.7c2.3,0,4.6-0.9,6.4-2.7l35.7-35.7c3.5-3.5,3.5-9.3,0-12.8
			s-9.3-3.5-12.8,0l-20.2,20.2v-97.3c0-5-4.1-9.1-9.1-9.1s-9.1,4.1-9.1,9.1v97.3l-20.2-20.2c-3.5-3.5-9.3-3.5-12.8,0
			S199.363,447.925,202.863,451.525z"
                />
              </>
            )}

            {((parseInt(bobBasis) === 0 &&
              parseInt(secondaryBasis) === 0 &&
              parseInt(bobPhoton) === 0) ||
              (parseInt(bobBasis) === 0 &&
                parseInt(secondaryBasis) === 1 &&
                parseInt(bobPhoton) === 0)) && (
              <>
                {/* Left */}
                <path
                  d="M38.363,287.025c1.8,1.8,4.1,2.7,6.4,2.7c2.3,0,4.6-0.9,6.4-2.7c3.5-3.5,3.5-9.3,0-12.8l-20.2-20.2h97.3
			c5,0,9.1-4.1,9.1-9.1s-4.1-9.1-9.1-9.1h-97.3l20.2-20.2c3.5-3.5,3.5-9.3,0-12.8s-9.3-3.5-12.8,0l-35.7,35.7
			c-3.5,3.5-3.5,9.3,0,12.8L38.363,287.025z"
                />
                {/* Right */}
                <path
                  d="M352.563,244.925c0,5,4.1,9.1,9.1,9.1h97.3l-20.2,20.2c-3.5,3.5-3.5,9.3,0,12.8c1.8,1.8,4.1,2.7,6.4,2.7s4.6-0.9,6.4-2.7
			l35.7-35.7c3.5-3.5,3.5-9.3,0-12.8l-35.7-35.7c-3.5-3.5-9.3-3.5-12.8,0s-3.5,9.3,0,12.8l20.2,20.2h-97.3
			C356.563,235.825,352.563,239.925,352.563,244.925z"
                />
              </>
            )}

            {((parseInt(bobBasis) === 1 &&
              parseInt(secondaryBasis) === 1 &&
              parseInt(bobPhoton) === 0) ||
              (parseInt(bobBasis) === 1 &&
                parseInt(secondaryBasis) === 0 &&
                parseInt(bobPhoton) === 0)) && (
              <>
                {/* Top Left */}
                <path
                  d="M137.763,78.125c0-5-4.1-9.1-9.1-9.1h-50.5c-5,0-9.1,4.1-9.1,9.1v50.5c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1v-28.6
			l68.8,68.8c1.8,1.8,4.1,2.7,6.4,2.7s4.6-0.9,6.4-2.7c3.5-3.5,3.5-9.3,0-12.8l-68.8-68.8h28.6
			C133.663,87.225,137.763,83.125,137.763,78.125z"
                />
                {/* Bottom Right */}

                <path
                  d="M321.063,333.825l68.8,68.8h-28.6c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1h50.5c5,0,9.1-4.1,9.1-9.1v-50.5
			c0-5-4.1-9.1-9.1-9.1s-9.1,4.1-9.1,9.1v28.6l-68.8-68.8c-3.5-3.5-9.3-3.5-12.8,0C317.463,324.525,317.463,330.225,321.063,333.825
			z"
                />
              </>
            )}

            {((parseInt(bobBasis) === 1 &&
              parseInt(secondaryBasis) === 1 &&
              parseInt(bobPhoton) === 1) ||
              (parseInt(bobBasis) === 1 &&
                parseInt(secondaryBasis) === 0 &&
                parseInt(bobPhoton) === 1)) && (
              <>
                {/* Bottom Left  */}
                <path
                  d="M78.163,352.125c-5,0-9.1,4.1-9.1,9.1v50.5c0,5,4.1,9.1,9.1,9.1h50.5c5,0,9.1-4.1,9.1-9.1s-4.1-9.1-9.1-9.1h-28.6
			l68.8-68.8c3.5-3.5,3.5-9.3,0-12.8s-9.3-3.5-12.8,0l-68.8,68.8v-28.6C87.263,356.225,83.163,352.125,78.163,352.125z"
                />
                {/* Top Right */}
                <path
                  d="M321.063,168.825c1.8,1.8,4.1,2.7,6.4,2.7s4.6-0.9,6.4-2.7l68.8-68.8v28.6c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1v-50.5
			c0-5-4.1-9.1-9.1-9.1h-50.5c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1h28.6l-68.8,68.8
			C317.463,159.625,317.463,165.325,321.063,168.825z"
                />
              </>
            )}
          </g>
        </g>
      </svg>
    </div>
  );
}
